import { useState } from 'react'
import { useNFTContract } from './useContract'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useMemo } from 'react'

export const useNFTPrice = () => {
    const nftContract = useNFTContract()

    const [price, setPrice] = useState(0)

    // const fetchPrice = useCallback(async () => {
    //     if (nftContract) {
    //         try {
    //             const price = await nftContract.NFTPrice()
    //             setPrice(price)
    //         } catch (e) {
    //             console.log(e)
    //         }
    //     }
    // }, [nftContract])

    // useEffect(() => {
    //     fetchPrice()
    // }, [fetchPrice])

    // useEffect(() => {
    //     const refreshInterval = setInterval(fetchPrice, 10000)
    //     return () => clearInterval(refreshInterval)
    // }, [fetchPrice])

    return useMemo(() => {
        return { price }
    }, [price])
}