import React, { useCallback } from "react";
import { CContainer, CButton, CRow, CCol } from "@coreui/react";
import infoimage from "../Assets/images/v1.gif";
import banner from "../Assets/images/V3.mp4";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerToken, setupNetwork } from "../utils/setupNetwork";
import useActiveWeb3React from "../hooks/useWeb3";

import { mglxContract, usdcContract, usdtContract } from "../config";
import { Balance } from "./Balance";
import Nfttoggle from "./Nfttoggle";
import ReactPlayer from "react-player";


const Banner = () => {
  const { library } = useActiveWeb3React();

  const addMainnet = useCallback(() => {
    setupNetwork({ library });
  }, [library]);

  const addToken = useCallback(() => {
    registerToken(mglxContract, "DWC", 18);
  }, []);

  const addUSDT = useCallback(() => {
    registerToken(
      usdtContract,
      "USDT",
      6,
      "https://polygonscan.com/token/images/tether_32.png"
    );
  }, []);

  const Completionist = () => (
    <div className="countDownMu">Staking is LIVE Now!!</div>
  );

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <div className="countDownMu">
          <b>
            {hours} <span>Hours</span>
          </b>
          :
          <b>
            {minutes} <span>Minutes</span>
          </b>
          :
          <b>
            {seconds} <span>Seconds</span>
          </b>
          <p>
            NFT Staking Begins on 04/04/2023 <span>12:30 PM (GMT)</span>
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <section className="banner">
        <CContainer>
          <div className="banner-area">
            <CRow
              xs={{ gutter: 4 }}
              className=" flex-row-reverse align-items-center"
            >
              <CCol sm={12} md={6}>
                 <ReactPlayer
                  className="color-changing-element"
                  // src={banner}
                  // height={480}
                  // width={850}
                      // className="react-player nftvideo "
                      url={banner}
                      width="500"
                      height="600"
                      muted
                      loop
                      playing="true"
                      playsinline="true"
                      />
              </CCol>
              <CCol sm={12} md={6}>
                <div className="info-group" data-aos="fade-right">
                  <h1 className="mx-auto ">
                  Empowering Digital Ownership with DeFi World Cutting-Edge
{" "}
                    <span className="nftxtmain">NFT staking</span>
                  </h1>
                  <p className=" mt-4 mx-auto ">DeFi World proudly unveils its groundbreaking NFT Staking Platform on the Polygon Chain, offering lightning-fast transactions, rock-solid security, and a pathway to diversify investments.
                  Explore the future of decentralized finance (DeFi) with us.</p>

                </div>
                <div className="buttonGroup2 bannerbtn  d-flex flex-sm-row flex-column align-items-sm-start align-items-center mt-4">
                  <button class="btn1 btn5" onClick={addMainnet}>Add Mainnet</button>
                  <button class="btn1 btn5" onClick={addToken}> Add DWC</button>
                  <button class="btn1 btn5" onClick={addUSDT}> Add USDT</button>
                </div>
              </CCol>
            </CRow>
          </div>

          <Nfttoggle />
        </CContainer>
      </section>

      <section className="nftBalance">
        <Balance />
      </section>
      <section className="informationMU">
        <CContainer>
          <CRow className="align-items-center">
            <CCol md="6">
              <div className="infotxt">
                <h3>Unlocking the Advantages of NFT Staking on DeFi World:</h3>
                <p>
                  <span>Lightning-Fast Transactions:</span> Polygon, a Layer 2
                  scaling solution, empowers DEFI World users with swift and
                  cost-effective transactions, eliminating concerns about hefty
                  gas fees and sluggish transaction speeds that often plague
                  Ethereum.
                </p>
                <p>
                  <span>Security and Decentralization at the Core:</span> DEFI
                  World's NFT staking platform is firmly rooted in Polygon,
                  providing a fortress of security and decentralization. Users
                  exercise complete control over their assets, bolstered by
                  smart contracts that ensure equitable and transparent rewards
                  distribution.
                </p>
                <p>
                  <span>Expand Your Investment Horizons:</span> NFT staking
                  opens doors for users to diversify their portfolios by
                  accumulating rewards alongside the inherent value of their
                  NFTs. This dynamic income stream acts as a shield against the
                  crypto market's fluctuations, creating financial stability.
                </p>
              </div>
            </CCol>
            <CCol md="6">
              <div className="infoimage text-end">
                <img src={infoimage} alt="infoimage" width={512} height={512} />
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    </>
  );
};

export default Banner;
