import { useMemo } from "react"
import { useNFTContract, useStakeContract } from "./useContract"
import { useEffect } from "react"
import { useCallback } from "react"
import { useState } from "react"

export const useTotalNFT = () => {
    const nftContract = useStakeContract()
    const [balance, setBalance] = useState(0)
    const fetchBalance = useCallback(async () => {
        if (nftContract) {
            const balance = await nftContract.totalNFT()
            setBalance(balance.toString())
        }
    }, [nftContract])

    useEffect(() => {
        fetchBalance()
    }, [fetchBalance])

    useEffect(() => {
        const refreshInterval = setInterval(fetchBalance, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchBalance])

    return useMemo(() => {
        return { balance: parseInt(balance) }
    }, [balance])
}