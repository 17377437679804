import React from 'react'
import { useTotalNFT } from '../../hooks/useTotalNFT'
import { useWithdraw } from '../../hooks/useWithdrawFromContract'

const Nftsecadmin = () => {
  const { balance } = useTotalNFT()

  const { withdrawNFT } = useWithdraw()
  return (
    <>

      <section className="mainbox_ad adminform">
        <div className="os_container_admin mb-20 mt-20">
          <div className='titlebox text-center blinkers  '>
            <h2 className='h1'>NFT </h2>

          </div>
          <div className="boxmain_ad">
            <div className="boxinner_ad">
              <div className="os_row_ad items-center  boxpart_ad">
                <div className="leftbx_ad">
                  <strong>
                    Total NFT Staked
                  </strong>
                </div>
                <div className="inputbox_ad">
                  <input
                    autoComplete="off"
                    name="inputField"
                    placeholder="Enter count"
                    type="text"
                    value={balance}
                    disabled
                  />
                  <button type="button" onClick={withdrawNFT}>
                    withdraw
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default Nftsecadmin