import useActiveWeb3React from "./useWeb3";
import { useMemo } from "react";
import { getContract } from "../utils/getContract";
import multiCallAbi from '../config/abis/multicall.json';
import stakeABI from '../config/abis/stakeABI.json';
import tokenABI from '../config/abis/tokenABI.json';
import nftABI from '../config/abis/IERC1155.json';

import { multiCallAddress, nftContract, stakeContract } from "../config";
import { simpleRpcProvider } from "../utils/providers";

export const useContract = (address, abi) => {
    const { account, library } = useActiveWeb3React()
    return useMemo(() => getContract(address, abi, account ? library.getSigner() : null), [address, abi, library]);
}

export function useMulticallContract() {
    return useContract(multiCallAddress, multiCallAbi);
}

export function useStakeContract() {
    return useContract(stakeContract, stakeABI)
}

export function useTokenContract(tokenAddress) {
    return useContract(tokenAddress, tokenABI)
}

export function useNFTContract() {
    return useContract(nftContract, nftABI)
}