import React, { useEffect } from "react";
import { useState } from "react";
import logo from "../Assets/images/logo.gif";
import logoname from "../Assets/images/logo.gif";


import "../Assets/css/header.css";
import useAuth from '../hooks/useAuth';
import { ConnectorNames } from "../utils/web3React";
import useActiveWeb3React from "../hooks/useWeb3";

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [showHamburger, setHamburger] = useState(false);
  const [DefiPrice, setDefiPrice] = useState();
  const { login } = useAuth()
  const { account } = useActiveWeb3React()

  function Price() {
    try {
      const data = fetch("https://oc-price-api.onrender.com/oc-price")
        .then((res) => res.json())
        .then((json) => {
          setDefiPrice((Math.floor(json.price * 100) / 100).toFixed(2));
        });
    } catch (error) {
      console.log(error);
    }
  }

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
    setHamburger(!showHamburger);
  };

  useEffect(() => {
    Price();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      Price();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <header className={`${showNavbar && "openmenu"}`}>
        <div className="container   mx-auto ">
          <div className="row align-items-center  ">
            <div className="headersec">
              <div className="col-6 col-md-2 text-start">
                <div className="logo">
                  <img
                    src={logo}
                    alt="logo"
                    width="140"
                    height="140"
                    className="d-none d-md-block"
                  />
                  <img
                    src={logoname}
                    alt="logo"
                    width="275"
                    height="120"
                    className="d-block d-md-none"
                  />
                </div>
              </div>

              <div className="buttonGroup ">
                <button class="btn1 " onClick={() => {
                  login(ConnectorNames.Injected)
                }} disabled={account}>
                  {
                    !account ? 'Connect' : (account.slice(0, 4) + '...' + account.slice(-4))
                  }
                </button>
              </div>
            </div>
            <div className="logoDesktop">
              <ul>
                <li>D</li>
                <li>e</li>
                <li>f</li>
                <li>i</li>
                </ul>
                </div>
                <div className="logoDesktop2">
                <ul>
                <li>W</li>
                <li>o</li>
                <li>r</li>
                <li>l</li>
                <li>d</li>
              
              
              </ul>
            </div>
            <div className="col-6 col-md-2 text-end">
              <div className="rightboxmenu"></div>
            </div>
          </div>
        </div>
        <div className="before">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
    </>
  );
};

export default Header;
