import React from "react";
import { CContainer, CButton, CRow, CCol } from "@coreui/react";
import ReactPlayer from "react-player";
import mainnfg from "../Assets/images/v5_final.mp4";
import Collected from "../Assets/images/collected.png";

import Generated from "../Assets/images/Generated.png";
import Pending_Days from "../Assets/images/Pending_Days (2).png";
import Pending_Icon from "../Assets/images/pending-defi.png";
import topframe from "../Assets/images/linetoprightbox.77816ea2ca1728496860949776011703.svg";
import bottomframe from "../Assets/images/linebottomrightbox.d334904919ed78e3eaa5a8d5cdfc6703.svg";

import { maxCap, nftContract, usdtContract } from "../config";
import { useSelector } from "../redux/store";
import { useStakeInfo } from "../state/stake/hooks";
import { formatNumber } from "../utils";
import { ethers } from "ethers";
import { useNFTPrice } from "../hooks/useNFTPrice";
import { useApprove } from "../hooks/useApprove";
import { useBuyNFT } from "../hooks/useBuyNFT";
import { useTokenBalance } from "../hooks/useTokenBalance";
import { useNFTBalance } from "../hooks/useNFTBalance";
import { useAccountInfo } from "../state/account/hooks";
import Analytics from "./Analytics/Analytics";
import { formatEther, parseEther } from "ethers/lib/utils";

export const Nftsec = () => {
  const { depositedAt, returned, generated, maxReturn, dwPrice, totalDays } = useSelector(
    (state) => state.stake
  );
  useStakeInfo();

  const { price } = useNFTPrice()
  // const { isApproved, approve, isApproving } = useApprove(usdtContract, nftContract, price)
  const { isBuying, buyNFT } = useBuyNFT()
  const { balance } = useTokenBalance(usdtContract)
  const { nftBalance } = useSelector((state) => state.account)
  useAccountInfo()

  let days = depositedAt * 1000 + (totalDays * 1000) - Date.now();

  if (days < 0) {
    days = 0;
  } else {
    days = days / (24 * 60 * 60 * 1000);
  }

  console.debug(generated, dwPrice)

  return (
    <div>
      <section className="nftsec">
        <CContainer>
          <div className="listViewBx ms:mt-5  borderboxmain ">
            <img class="linetopleft" src={topframe} alt="border line" />
            <div className="mainsec">
              {/* <CRow className="justify-content-center align-items-center">
                <CCol>
                  <div className="nftmainimg text-center">
                    <ReactPlayer
                      className="react-player nftvideo "
                      url={mainnfg}
                      width="500"
                      height="600"
                      muted
                      loop
                      playing="true"
                      playsinline="true"
                    />
                  </div>
                  <div className="infonft">

                    <h2>DeFi World NFT Pass</h2>
                    <button class="kave-btn">
                      <span class="kave-line"></span>
                      price: {price ? ethers.utils.formatUnits(price, "6") : "0"} USDT
                    </button>
                    <pre>
                      Your balance: {balance ? ethers.utils.formatUnits(balance, "6") : "0"} USDT
                    </pre>
                    {
                      !isStaked && <pre>
                        You have {nftBalance} NFTs
                      </pre>}
                  </div>
                </CCol>
              </CRow> */}
              {/* <div className="buttonGroup2 bannerbtn2  d-flex     align-items-center mt-4">
                {
                  !isStaked && !isApproved && <button class="btn1 btn5"
                    onClick={approve}
                    disabled={isApproving}
                  >
                    {
                      isApproved ? 'Approved' : (isApproving ? 'Approving...' : 'Approve USDT')
                    }
                  </button>
                }

                {
                  !isStaked && (isApproved && (nftBalance === 0)) && <button class="btn1 btn5"
                    onClick={buyNFT}
                    disabled={isBuying}
                  >
                    {
                      isBuying ? 'Buying...' : 'Buy'
                    }
                  </button>
                }

                {
                  <a class="btn1 btn5" href="#stakeDiv"
                  >
                    Stake Now
                  </a>
                }
              </div> */}

              <hr />
              <div className="text-center">
                <h2>Stake Statistics</h2>
              </div>
              <div className="os_row2 osrow ">
                {/* <div className="col4 ">
                  <div className="inrInfoBx">
                    <div className="imginrBx">
                      <img src={Collected} alt="Collected" />
                    </div>
                    <strong>
                      {generated ? formatNumber(generated) : "0"}
                      <p>DWC Generated</p>
                    </strong>
                  </div>
                </div> */}
                <div className="col4 ">
                  <div className="inrInfoBx">
                    <div className="imginrBx">
                      <img src={Collected} alt="Collected" />
                    </div>
                    <strong>
                      {(generated && dwPrice) ? formatNumber(parseEther(parseFloat((formatEther(generated)) * dwPrice).toString())) : "0"}
                      <p>Generated (USDT)</p>
                    </strong>
                  </div>
                </div>
                <div className="col4 ">
                  <div className="inrInfoBx">
                    <div className="imginrBx">
                      <img src={Collected} alt="Collected" />
                    </div>
                    <strong>
                      {(generated && dwPrice) ? formatNumber(generated) : "0"}
                      <p>Generated (DWC)</p>
                    </strong>
                  </div>
                </div>
                {/* <div className="col4">
                  <div className="inrInfoBx">
                    <div className="imginrBx">
                      <img src={Generated} alt="Generated" />
                    </div>
                    <strong>
                      {returned ? formatNumber(returned) : "0"}
                    </strong>
                    <p>DWC Collected</p>
                  </div>
                </div> */}
                <div className="col4">
                  <div className="inrInfoBx">
                    <div className="imginrBx">
                      <img src={Pending_Days} alt="Collected" />
                    </div>
                    <strong>
                      {Math.round(days)}
                    </strong>
                    <p>Pending Days</p>
                  </div>
                </div>
                <div className="col4">
                  <div className="inrInfoBx">
                    <div className="imginrBx">
                      <img src={Pending_Icon} alt="Pending Icon" />
                    </div>
                    <strong>
                      {depositedAt == 0
                        ? "0"
                        : `${parseFloat(
                          (
                            maxReturn -
                            parseFloat(ethers.utils.formatEther(returned))
                          ).toFixed(2)
                        ).toLocaleString()}`}
                    </strong>
                    <p>Pending (DWC)</p>
                  </div>
                </div>
                <div className="col4">
                  <div className="inrInfoBx">
                    <div className="imginrBx">
                      <img src={Pending_Icon} alt="Pending Icon" />
                    </div>
                    <strong>
                      {depositedAt != 0 && dwPrice
                        ? `${parseFloat(
                          ((
                            maxReturn -
                            parseFloat(ethers.utils.formatEther(returned))
                          ) * dwPrice).toFixed(2)
                        ).toLocaleString()}`
                        : "0"}
                    </strong>
                    <p>Pending (USDT)</p>
                  </div>
                </div>


              </div>

            </div>
            <img class="linebottomright" src={bottomframe} alt="border line" />
          </div>


        </CContainer>

      </section>
      <Analytics />
    </div>
  );
};

export default Nftsec;
