export const stakeContract = "0xEF708EFca46D069abBb80FF44a4a1F5ad5988e18"
export const mglxContract = "0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F"

export const nftContract = "0xb5f8cE861bcc32475bc811bE9a7fB2AC4249b0f6"

export const usdtContract = "0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F"
export const usdcContract = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174"

export const tokenID = "1"
export const openSeaUrl = "https://opensea.io/assets/matic/0xb5f8ce861bcc32475bc811be9a7fb2ac4249b0f6/1"
export const multiCallAddress = "0x4445286592CaADEB59917f16826B964C3e7B2D36" //"0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B"


export const BASE_BSC_SCAN_URLS = 'https://polygonscan.com'
export const BASE_URL = 'https://defiworldnft.netlify.app/'
export const chainRPC = "https://special-spring-telescope.matic.quiknode.pro/a741e9d69d608d51b43edc54a5017d657dccfd85/"

export const networkName = "Polygon Mainnet"
export const minWithdraw = 10

export const maxCap = 2713.33
export const defaultNetwork = 137

export const graphQl = "https://api.thegraph.com/subgraphs/name/whyusethis/defiworld-stake-api"