import { constants } from "ethers"
import { formatNumber, shortAddress } from "."
import { showError, showFulfill, showPromise } from "./pageHelpers"

export const _stakeNFT = async (contractInst, tokenId) => {
    try {
        console.log(`Initiaiting txn..`)
        let txn = await contractInst.stakeDWNFT()
        console.log(`Transaction done`)
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        let r = txn.wait()
        await showPromise(r)
        showFulfill(`NFT Sucessfully Staked`, 'Transaction Confirmed')
    } catch (err) {
        console.log(err)
        showError(err.reason ? err.reason : (err.data ? err.data.message : err.message))
    }
}

export const _approve = async (tokenInst, spender) => {
    try {
        let txn = await tokenInst.setApprovalForAll(spender, true)
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        let r = txn.wait()
        await showPromise(r)
        showFulfill(`Sucessfully approved NFT for staking!`, 'Transaction Confirmed')
    } catch (err) {
        showError(err.reason ? err.reason : (err.data ? err.data.message : err.message))
    }
}


export const _claim = async (stakeInstance) => {
    try {
        let txn = await stakeInstance.withdrawDW()
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        let r = txn.wait()
        await showPromise(r)
        showFulfill(`Sucessfully claimed DWC!`, 'Transaction Confirmed')
    } catch (err) {
        showError(err.reason ? err.reason : (err.data ? err.data.message : err.message))
    }
}
