import { useState } from "react";
import useActiveWeb3React from "./useWeb3";
import { useTokenContract } from "./useContract";
import { useCallback } from "react";
import { useEffect } from "react";
import { useMemo } from "react";

export const useTokenBalance = (tokenAddress) => {
    const { account } = useActiveWeb3React();
    const [balance, setBalance] = useState(0);
    const tokenContract = useTokenContract(tokenAddress);
    const fetchBalance = useCallback(async () => {
        if (tokenContract && account) {
            const balance = await tokenContract.balanceOf(account);
            setBalance(balance.toString());
        }
    }, [tokenContract, account]);
    useEffect(() => {
        fetchBalance();
    }, [fetchBalance]);
    useEffect(() => {
        const refreshInterval = setInterval(fetchBalance, 10000);
        return () => clearInterval(refreshInterval);
    }, [fetchBalance]);
    return useMemo(() => {
        return { balance };
    }, [balance]);
}