import React from 'react';
import '../Adminreport/Adminreport.css';
import Table1 from './Table1';
import Extable from './Extable';
import { useTotalNFT } from '../../../hooks/useTotalNFT';
import { useTotalNFTStaked } from '../../../hooks/useTotalNFTStaked';
import { formatEther } from '@ethersproject/units';

function AdminReport() {
  const { balance } = useTotalNFT();

  const nftStaked = useTotalNFTStaked({
    fromTimestamp: ((Date.now() / 1000) - 60 * 60 * 24 * 7).toFixed(0),
  });

  const totalStaked = useTotalNFTStaked({});

  const sumPrice = totalStaked.reduce((acc, cur) => acc + parseFloat(formatEther(cur.nftPriceAtStake)), 0);
  const dailyVolume = nftStaked.reduce((acc, cur) => acc + parseFloat(formatEther(cur.nftPriceAtStake)), 0);

  return (
    <section className="boxsmain" >
      <div className="os_container2">
        <div className="titleh2">
          <h2>Analytics</h2>
        </div>
        <div className="os_row">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="cardbox">
              <p>Total Staked NFTs</p>
              <p>{balance}</p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="cardbox">
              <p>NFT Staked in 24 hours</p>
              <p>{nftStaked.length}</p>
            </div>
          </div>
        </div>
        <div className="titleh2">
          <h2>Payout history</h2>
        </div>
        <div className="os_row">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="cardbox">
              <p>Total NFT (USDT)</p>
              <p>{sumPrice.toFixed(2)}</p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="cardbox">
              <p>Daily Volume (USDT)</p>
              <p>{dailyVolume.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
      <Table1 />
      <Extable />
    </section>
  );
}

export default AdminReport; 
