import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { fetchStakeInfo } from "."
import useActiveWeb3React from "../../hooks/useWeb3"

export const useStakeInfo = () => {
    const dispatch = useDispatch()
    const { account } = useActiveWeb3React()
    useEffect(() => {
        if(account) {
            dispatch(fetchStakeInfo(account));
        }
    }, [dispatch, account])
}