import { useCallback, useEffect, useMemo } from "react";

import { fetchAccountInfo } from "."
import { useNFTContract, useStakeContract, useTokenContract } from "../../hooks/useContract";
import { useAppDispatch } from "../../redux/store"
import { _approve, _claim, _stakeNFT } from "../../utils/stakeHelpers";
import useActiveWeb3React from "../../hooks/useWeb3";
import { stakeContract } from "../../config";
import { fetchStakeInfo } from "../stake";

export const useAccountInfo = () => {
    const { account } = useActiveWeb3React()
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (account) {
            dispatch(fetchAccountInfo(account))
        }
    }, [dispatch, fetchAccountInfo, account]);
}

export const useStake = () => {
    const dispatch = useAppDispatch()
    const { account } = useActiveWeb3React()
    const stakeInstance = useStakeContract()
    const tokenInstance = useNFTContract()

    const stakeNFT = useCallback(async (tokenId) => {
        const txn = await _stakeNFT(stakeInstance, tokenId)
        dispatch(fetchAccountInfo(account))
        dispatch(fetchStakeInfo(account))
    }, [stakeInstance, dispatch, account])

    const approveNFT = useCallback(async () => {
        const txn = await _approve(tokenInstance, stakeContract)
        dispatch(fetchAccountInfo(account))
    }, [account, dispatch, tokenInstance])

    return { stakeNFT, approveNFT }
}

export const useClaim = () => {
    const dispatch = useAppDispatch()
    const { account } = useActiveWeb3React()
    const stakeContract = useStakeContract()

    const claim = useCallback(async () => {
        const txn = await _claim(stakeContract)
        dispatch(fetchStakeInfo(account))
    }, [stakeContract, account])

    return { claim }
}