import { useCallback, useEffect, useMemo, useState } from "react"
import stakeABI from "../config/abis/stakeABI.json"
import { stakeContract } from "../config"
import { getContract } from "../utils/getContract"
import { formatEther } from "@ethersproject/units"
import { AddressZero } from "@ethersproject/constants"

export const useStakerDetail = (address) => {

    const [data, setData] = useState({})

    const fetchDetails = useCallback(async () => {
        try {
            const stakeInstance = getContract(stakeContract, stakeABI)
            const userData = await stakeInstance.userDatas(address)
            const generated = await stakeInstance.getGenerated(address)

            const perNft = await stakeInstance.getTotalReturnInDWC(address)
            const dwPrice = await stakeInstance.getDWPrice()
            const totalDays = await stakeInstance.totalDays()

            setData({
                depositedAt: userData.deposited.toNumber(),
                generated: generated.toJSON(),
                lastWithdrawn: userData.lastWithdrawn.toNumber(),
                nftPriceAtStaked: parseFloat(formatEther(userData.nftPriceAtStake)),
                returned: userData.returned.toJSON(),
                returnedUSDT: userData.usdtReturned.toJSON(),
                isStaked: userData.user != AddressZero,
                maxReturn: parseFloat(formatEther(perNft)),
                dwPrice: parseFloat(formatEther(dwPrice)),
                totalDays: totalDays.toNumber()
            })
        } catch (err) {
            console.debug(err)
            setData({
                depositedAt: 0,
                generated: 0,
                lastWithdrawn: 0,
                nftPriceAtStaked: 0,
                returned: 0,
                returnedUSDT: 0,
                generated: false,
                maxReturn: 0,
                dwPrice: 0,
                totalDays: 0
            })
        }
    }, [])

    useEffect(() => {
        fetchDetails()
    }
        , [fetchDetails])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchDetails()
        }, 10000)
        return () => clearInterval(interval)
    }, [fetchDetails])

    return useMemo(() => data, [data])
}