import React from 'react'
import "../Faqs/Faqs.css"
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "01 : How many public NFTs are available for users to stake?",
            content: `
           
There are a total of 3,500 public NFTs available for users to stake on the platform. This provides a wide variety of options for users interested in participating in NFT staking.`,
        },
        {
            title: "02 :   What will I receive in return after staking an NFT?",
            content: `Upon staking an NFT, users will be rewarded with DWC (DEFI World Coin). This reward system incentivizes participation in NFT staking and allows users to accrue DWC by holding NFTs.`,
        },
        {
            title: "03 :    For how many days will the NFT be staked?",
            content: `The total duration for NFT staking is 400 days. During this period, your NFT will be locked in the staking contract, accruing rewards over time.`,
        },
        {
            title: "04 :  What is the percentage of return on NFT staking?",
            content: `The return on NFT staking is 2X (double) the price of the NFT. This significant return rate is an attractive aspect of staking on the platform, offering substantial rewards for participants.
            .
            `,
        },
        {
            title: "05 : Can I unstake the NFT after staking it?",
            content: `No, once an NFT is staked, it cannot be unstaked. This policy is in place to maintain the integrity of the staking process and the rewards system. Users should be sure of their commitment before staking their NFTs.`,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#50dbff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#50dbff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true   
};
const Faqs = () => {
    return (
        <section className="faqs" id='faqsSrl'>
            <div className="container">
                <div className='titlebox text-center blinkers  '>
                    <h6 className='   mb-1 md:mb-0 faQ'>FAQ's</h6>
                    <h2 className='h1'>Quick Questions</h2>
                   
                </div>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    )
}

export default Faqs