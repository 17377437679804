import { useCallback, useEffect, useMemo, useState } from "react"
import { graphQl } from "../config"

export const useStakeData = () => {
    const [data, setData] = useState([])
    const url = graphQl
    const query = `
    {
        dwstakers(orderBy:blockNumber orderDirection:desc) {
          id
          user
          tokenId
          nftPriceAtStake
          transactionHash
          blockTimestamp
        }
      }
    `

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    }

    const fetchStakeData = useCallback(async () => {
        const response = await fetch(url, options)
        const json = await response.json()
        setData(json.data.dwstakers)
    }, [url, options, setData])

    useEffect(() => {
        fetchStakeData()
    }, [fetchStakeData])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchStakeData()
        }, 10000)
        return () => clearInterval(interval)
    }, [fetchStakeData])

    return useMemo(() => data, [data])


}