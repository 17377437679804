import { useCallback } from "react"
import { useNFTContract } from "./useContract"
import { useState } from "react"
import { useMemo } from "react"
import { showError, showPromise } from "../utils/pageHelpers"

export const useBuyNFT = () => {
    const nftContract = useNFTContract()
    const [isBuying, setIsBuying] = useState(false)

    const buyNFT = useCallback(async () => {
        if (nftContract) {
            try {
                setIsBuying(true)
                const tx = await nftContract.BuyNFT()
                const receipt = tx.wait()
                await showPromise(receipt)
                setIsBuying(false)
            } catch (e) {
                console.log(e)
                showError(e.reason ? e.reason : (e.data ? e.data.message : e.message))
            } finally {
                setIsBuying(false)
            }
        }
    }, [nftContract])

    return useMemo(() => {
        return { isBuying, buyNFT }
    }, [isBuying, buyNFT])
}