import { useCallback } from "react"
import { useStakeContract } from "./useContract"
import { showError, showPromise } from "../utils/pageHelpers"

export const useWithdraw = () => {
    const nftContract = useStakeContract()

    const withdrawNFT = useCallback(async () => {
        try {
            const txn = await nftContract.withdrawNFT()
            showPromise(txn.wait(), {
                pending: 'Withdrawing NFT...',
                complete: 'NFT Withdrawn!',
                error: 'Error Withdrawing NFT'
            })
            return txn
        } catch (err) {
            showError(err.reason ? err.reason : (err.data ? err.data.message : err.message), 'Error Withdrawing NFT')
        }
    }, [nftContract])

    return { withdrawNFT }
}