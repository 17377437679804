import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { useTotalNFTStaked } from "../../../hooks/useTotalNFTStaked";
import { formatNumber } from "../../../utils";
import { useStakerDetail } from "../../../hooks/useStakerDetail";
import { useSelector } from "../../../redux/store";
import { formatEther } from "@ethersproject/units";
import { getStakeDetails } from "../../../utils/getStakeDetails";
import moment from "moment";
import { exportToExcel } from "../../../utils/exportExcel";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const TableRowData = ({ index, address, row }) => {
  const data = useStakerDetail(address);

  return <TableRow
    key={row.id}
    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  >
    <TableCell component="th" scope="row">
      {index + 1}
    </TableCell>
    <TableCell>
      {
        address.slice(0, 6) + "..." + address.slice(-4)
      }
    </TableCell>
    <TableCell>
      {dayjs.unix(row.blockTimestamp).format("DD MMM hh:mm A")}
    </TableCell>
    <TableCell>{formatNumber(row.nftPriceAtStake)} USDT</TableCell>
    <TableCell>
      {
        data.isStaked ? (parseFloat(formatEther(row.nftPriceAtStake)) / data.dwPrice).toFixed(2) + " DWC" : "0 DWC"
      }
    </TableCell>
    <TableCell>{(400 - dayjs().diff(dayjs.unix(row.blockTimestamp), "day"))} days</TableCell>
    <TableCell>
      {
        data.isStaked ? formatNumber(data.generated) + " DWC" : "0 DWC"
      }
    </TableCell>
    <TableCell>
      {
        data.isStaked ? formatNumber(data.returned) + " DWC" : "0 DWC"
      }
    </TableCell>
    <TableCell>
      {
        data.isStaked ? (data.maxReturn - parseFloat(formatEther(data.returned))).toFixed(2) + " DWC" : "0 DWC"
      }
    </TableCell>

  </TableRow>
}


const SimpleTable = ({ data, dwPrice }) => (

  <TableContainer component={Paper} className="mt-3">
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Sr.no</TableCell>
          <TableCell>Wallet Address</TableCell>
          <TableCell>Staked Date</TableCell>
          <TableCell>NFT Price at Stake (USDT) </TableCell>
          <TableCell colSpan={2}>Total Return</TableCell>
          <TableCell >Pending Days</TableCell>
          <TableCell colSpan={2}>Generated</TableCell>
          <TableCell colSpan={2}>Claimed</TableCell>
          <TableCell colSpan={2}>Pending</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => {

          if (parseInt((400 - dayjs().diff(dayjs.unix(row.blockTimestamp), "day"))) <= 7) {
            return <TableRowData key={index} index={index} address={row.user} row={row} />
          }
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

const shortcutsItems = [
  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

const Extable = () => {
  const [value, setValue] = useState(0);
  const [activeInactiveValue, setActiveInactiveValue] = useState("active");
  const [startDate, setStartDate] = useState((new Date() / 1000).toFixed(0));
  const [endDate, setEndDate] = useState((new Date() / 1000).toFixed(0));
  const { dwPrice } = useSelector((state) => state.stake);

  const [isExporting, setExporting] = useState(false)
  const [exportData, setExportData] = useState([])

  const data = useTotalNFTStaked({

  })

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleActiveInactiveChange = (event) => {
    setActiveInactiveValue(event.target.value);
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const exportExcel = useCallback(async () => {
    setExporting(true)
    try {
      let json = []
      for (let d in data) {
        let dta = data[d]
        let p = JSON.parse(localStorage.getItem(`data-${data[d].user}`) ?? {})

        json.push({
          "User": data[d].user,
          "Staked Date": moment(data[d].blockTimestamp, "DD MMM hh:mm A"),
          "NFT Price": `${formatNumber(dta.nftPriceAtStake)} USDT`,
          "Total Return (USDT)": p.isStaked ? (parseFloat(formatEther(dta.nftPriceAtStake)) * p.dwPrice).toFixed(2) + " USDT" : "0 USDT",
          "Total Return (DWC)": p.isStaked ? (parseFloat(formatEther(dta.nftPriceAtStake))).toFixed(2) + " DWC" : "0 DWC",
          "Pending Days": `${(400 - dayjs().diff(dayjs.unix(dta.blockTimestamp), "day"))} days`,
          "Generated (USDT)":
            p.isStaked ? (parseFloat(formatEther(p.generated)) * p.dwPrice).toFixed(2) + " USDT" : "0 USDT",
          "Generated (DWC)":
            p.isStaked ? (parseFloat(formatEther(p.generated))).toFixed(2) + " DWC" : "0 DWC",
          "Claimed (USDT)": p.isStaked ? (parseFloat(formatEther(p.returnedUSDT))).toFixed(2) + " USDT" : "0 USDT",
          "Claimed (DWC)": p.isStaked ? (parseFloat(formatEther(p.returned))).toFixed(2) + " DWC" : "0 DWC"
        })

        console.debug(d)
      }

      let name = `DWC-NFT Stake Data`
      exportToExcel(json, name)
      setExporting(false)
    } catch (err) {
      console.debug(err)
      setExporting(false)
    }
  }, [data])

  return (
    <section className="extable " >
      <h1>Expiry in 7 days</h1>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <SimpleTable data={data} dwPrice={dwPrice} />
      </Box>
    </section>
  );
};

export default Extable;
