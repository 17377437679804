import React, { useCallback, useEffect, useState } from 'react'
import { CContainer } from '@coreui/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LoaderIcon, toast } from 'react-hot-toast';
import { shortAddress } from '../utils';

import { format } from 'date-fns';
import { useAccountInfo, useClaim, useStake } from '../state/account/hooks';
import { useSelector } from '../redux/store';
import { useStakeInfo } from '../state/stake/hooks';
import { nftContract, tokenID, openSeaUrl, minWithdraw } from '../config';
import { ethers } from 'ethers';
import useActiveWeb3React from '../hooks/useWeb3';
import { useNFTTokendId } from '../hooks/useNFTTokenId';

export const Balance = () => {

    const copyMsg = () => toast("Copy Successfully !");

    const { account } = useActiveWeb3React()

    const { nftBalance, isApproved } = useSelector((state) => state.account)
    const { isStaked, depositedAt, generated, dwPrice, lastWithdrawn } = useSelector((state) => state.stake)
    const { tokenIds: userTokenId } = useNFTTokendId()

    const [isInTrn, setTransaction] = useState(false);
    const { approveNFT, stakeNFT } = useStake()
    const { claim } = useClaim()

    useAccountInfo()
    useStakeInfo()

    const { tokenIds } = useNFTTokendId()

    const [stakedOn, setStakedOn] = useState("");
    const [lastWithdrawAt, setLastWithdrawAt] = useState("");
    const minAmountToClaim = minWithdraw * dwPrice
    useEffect(() => {
        const dt = new Date(Math.round(depositedAt * 1000))
        setStakedOn(format(dt, "dd MMM hh:mm a"))
    }, [depositedAt, setStakedOn])

    useEffect(() => {
        const dt = new Date(Math.round(lastWithdrawn * 1000))
        setLastWithdrawAt(format(dt, "dd MMM hh:mm a"))
    }, [lastWithdrawn, setLastWithdrawAt])

    const claimBtn = useCallback(async () => {
        setTransaction(true)
        await claim()
        setTransaction(false)
    }, [claim, setTransaction])

    const approveBtn = useCallback(
        async () => {
            setTransaction(true)
            await approveNFT()
            setTransaction(false)
        },
        [approveNFT, setTransaction],
    )

    const stakeBtn = useCallback(
        async () => {
            if (nftBalance === 0) {
                toast.error("No NFT Found!")
                setTransaction(false)
                return;
            }
            setTransaction(true)
            await stakeNFT(userTokenId[0])
            setTransaction(false)
        },
        [stakeNFT, setTransaction, nftBalance, userTokenId],
    )

    return (
        <CContainer id='stakeDiv'>
            <div className='balanceViewBx mb-5 mb-md-0'>
                <ul>
                    <li>Your Balance <span>{nftBalance}</span></li>
                    <li>Token ID <span>{tokenID}
                        <CopyToClipboard text={tokenID} onCopy={copyMsg}>
                            <svg fill="#ffffff" height="30" width="30" version="1.1" viewBox="0 0 330 330"><g><path d="M35,270h45v45c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V75c0-8.284-6.716-15-15-15h-45V15  c0-8.284-6.716-15-15-15H35c-8.284,0-15,6.716-15,15v240C20,263.284,26.716,270,35,270z M280,300H110V90h170V300z M50,30h170v30H95  c-8.284,0-15,6.716-15,15v165H50V30z"></path><path d="M155,120c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15s-6.716-15-15-15H155z"></path> <path d="M235,180h-80c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15S243.284,180,235,180z"></path><path d="M235,240h-80c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h80c8.284,0,15-6.716,15-15C250,246.716,243.284,240,235,240z"></path></g></svg></CopyToClipboard></span>
                    </li>
                    <li>NFT <span>{shortAddress(nftContract)}
                        <CopyToClipboard text={nftContract} onCopy={copyMsg}><svg fill="#ffffff" height="30" width="30" version="1.1" viewBox="0 0 330 330"><g><path d="M35,270h45v45c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V75c0-8.284-6.716-15-15-15h-45V15  c0-8.284-6.716-15-15-15H35c-8.284,0-15,6.716-15,15v240C20,263.284,26.716,270,35,270z M280,300H110V90h170V300z M50,30h170v30H95  c-8.284,0-15,6.716-15,15v165H50V30z"></path><path d="M155,120c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15s-6.716-15-15-15H155z"></path> <path d="M235,180h-80c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15S243.284,180,235,180z"></path><path d="M235,240h-80c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h80c8.284,0,15-6.716,15-15C250,246.716,243.284,240,235,240z"></path></g></svg></CopyToClipboard></span>
                    </li>
                    <li>Price <span>1 DWC = {dwPrice.toFixed(2)} USDT</span></li>
                    {
                        isStaked && <li>Min. Claim <span>{minAmountToClaim.toFixed(2)} DWC (10 USDT)</span></li>
                    }
                    {
                        isStaked && <li>Staked On <span>{stakedOn}</span></li>
                    }
                    {
                        isStaked && parseFloat(generated ? ethers.utils.formatEther(generated) : "0") >= 0 && <li>Generated DWC <span>{parseFloat(generated ? parseFloat(ethers.utils.formatEther(generated)).toPrecision(4) : "0")} DWC</span></li>
                    }
                    {
                        isStaked && lastWithdrawAt && (lastWithdrawAt !== stakedOn) && <li>Last Withdrawn <span>{lastWithdrawAt}</span></li>
                    }
                </ul>
                {account && <div style={{ marginTop: "16px", textAlign: "center" }}>
                    {
                        (isApproved && !isStaked && nftBalance >= 0)
                        &&
                        <div className="buttonGroup bannerbtn2  d-flex     align-items-center mt-4">
                            <button className="kave-btn" disabled={isInTrn} href="javascript:;" onClick={stakeBtn}>
                                <span> {isInTrn && <LoaderIcon />}
                                    {!isInTrn && "Stake"}</span>
                            </button>
                        </div>
                    }
                    {
                        !isApproved &&
                        <div className="buttonGroup bannerbtn2  d-flex     align-items-center mt-4">
                            <button className="kave-btn" disabled={isInTrn} href="javascript:;" onClick={approveBtn}>
                                <span> {
                                    !isInTrn && "Approve NFT"
                                }
                                    {
                                        isInTrn && <LoaderIcon />
                                    }</span>
                            </button>
                        </div>
                    }
                    {
                        isStaked && parseFloat(generated ? ethers.utils.formatEther(generated) : "0") >= minAmountToClaim &&
                        <div className="buttonGroup bannerbtn2  d-flex     align-items-center mt-4">
                            <button className="kave-btn" disabled={parseFloat(generated ? ethers.utils.formatEther(generated) : "0") < minAmountToClaim || isInTrn} href="javascript:;" onClick={claimBtn}>
                                <span className='kave-line' style={{ display: "flex", justifyContent: "center" }}>{
                                    isInTrn && <LoaderIcon />
                                }
                                    {
                                        !isInTrn && "Claim DWC"
                                    }</span>
                            </button>
                        </div>
                    }
                </div>}
            </div>
        </CContainer>
    )
}