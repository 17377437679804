import React from "react";
import banner from "../../Assets/images/v5_final.mp4";
import ReactPlayer from "react-player";
import { CContainer, CButton, CRow, CCol } from "@coreui/react";
import Nftsecadmin from "./Nftsecadmin";
import '../Admin/Adminpanel.css'
import Tableadmin from "./Tableadmin";

const Adminpanel = () => {
  return (
    <>
      <section className="banner">
        <CContainer>
          <div className="admin-area">
            <CRow
              xs={{ gutter: 4 }}
              className=" flex-row-reverse align-items-center"
            >
              <CCol sm={12} md={6}>
                <ReactPlayer
                  className="color-changing-element"
                  // src={banner}
                  // height={480}
                  // width={850}
                  // className="react-player nftvideo "
                  url={banner}
                  width="500"
                  height="600"
                  muted
                  loop
                  playing="true"
                  playsinline="true"
                />
              </CCol>
              <CCol sm={12} md={6}>
                <div className="info-group" data-aos="fade-right">
                  <h1 className="mx-auto ">
                    Empowering Digital Ownership with DeFi World Cutting-Edge{" "}
                    <span className="nftxtmain">NFT staking</span>
                  </h1>
                  <p className=" mt-4 mx-auto ">
                    DeFi World proudly unveils its groundbreaking NFT Staking
                    Platform on the Polygon Chain, offering lightning-fast
                    transactions, rock-solid security, and a pathway to
                    diversify investments. Explore the future of decentralized
                    finance (DeFi) with us.
                  </p>
                </div>
              </CCol>
            </CRow>
          </div>
          <Nftsecadmin />
          <Tableadmin />
        </CContainer>
      </section>
    </>
  );
};

export default Adminpanel;
