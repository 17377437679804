import React from 'react'
import { useStakeData } from '../../hooks/useStakeData'
import moment from 'moment/moment'

const Tableadmin = () => {

  const data = useStakeData()
  return (
    <>
      <div className="table-responsive adminReportTable">
        {data.length !== 0 && <table class="table" style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th scope="col">sr no.</th>
              <th scope="col">Wallet Address</th>
              <th scope="col">Date</th>
              <th scope="col">Transaction</th>

            </tr>
          </thead>
          <tbody>
            {
              data.map((item, index) => {
                return <tr>
                  <th style={{ color: 'white' }} scope="row">{index + 1}</th>
                  <td style={{ color: 'white' }}>
                    {
                      item.user.slice(0, 6) + "..." + item.user.slice(-6)
                    }
                  </td>
                  <td style={{ color: 'white' }}>{
                    moment(item.blockTimestamp * 1000).format('MMMM Do YYYY, h:mm:ss a')
                  }</td>
                  <td style={{ color: 'white' }}>
                    <a href={`https://polygonscan.com/tx/${item.transactionHash}`} target="_blank" rel="noreferrer">
                      {
                        item.transactionHash.slice(0, 10) + "..." + item.transactionHash.slice(-10)
                      }
                    </a>
                  </td>
                </tr>
              })
            }
          </tbody>

        </table>}
        {data.length === 0 && <h5 className="text-center mt-5 mb-5">
          No data found
        </h5>}
      </div>
    </>
  )
}

export default Tableadmin